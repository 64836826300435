<template>
    <div v-if="isError">
        Error
    </div>
    <iframe v-else class="h-100 w-100" :src="source" style="border:none"></iframe>
</template>

<script setup>
    import { ref } from 'vue';
    import API from 'o365.modules.data.api.ts';

    const props = defineProps({
        source: String
    });
    const isError = ref(false);

</script>